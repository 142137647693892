body{
  background-color: #fafafa;
}
.product-img{
  width: 40%;
  padding-top: 20px;
}

.product-container{
  background-color: #ffffff;
  height: 100%;
  border-radius: 20px;
}

.colu-products{
  text-align: center;
}

.product-details-img{
  width: 50%;
}

.product-name{
  text-decoration: none !important;
  color: #000000;
  font-weight: 600;
}

.product-name:hover{
  color: #444444;
}

.price{
  font-weight: 600;
  color: #666666;
}

a:hover{
  text-decoration: none;
}

.wpp-link{
  color: #2CB241;
  font-weight: bold;
}

.wpp-link:hover{
  color: green;
}

.searchform-container{
  margin-bottom: -23px;
}

.share-ios{
  width: 18px;
}


/* CANVAS MENU */
html,
body {
  overflow-x: hidden; /* Prevent scroll on narrow devices */
}

#root{
  margin-top: 70px;
}

@media (max-width: 767.98px) {
  .offcanvas-collapse {
    position: fixed;
    top: 56px; /* Height of navbar */
    bottom: 0;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    background-color: #2a5781;
    transition: -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out;
    transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  .offcanvas-collapse.open {
    -webkit-transform: translateX(-1rem);
    transform: translateX(-1rem); /* Account for horizontal padding on navbar */
  }
}

.bg-dark {
  background-color: #252689 !important;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  color: rgba(255, 255, 255, .75);
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.nav-link{
  font-size: 18px;
  color: #ffffff !important;
}
.nav-underline .nav-link {
  padding-top: .75rem;
  padding-bottom: .75rem;
  color: var(--secondary);
}

.nav-underline .nav-link:hover {
  color: var(--blue);
}

.nav-underline .active {
  font-weight: 500;
  color: var(--gray-dark);
}

.text-white-50 { color: rgba(255, 255, 255, .5); }

.bg-purple { background-color: var(--purple); }

.border-bottom { border-bottom: 1px solid #e5e5e5; }

.box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }

.lh-100 { line-height: 1; }
.lh-125 { line-height: 1.25; }
.lh-150 { line-height: 1.5; }

.share-icon{
  padding: 5px;
  font-size: 22px;
}

#share-whatsapp a{
  color: #2CB241;
}

#share-twitter a{
  color: #1da1f2;
}

#share-mail a{
  color: #353535;
}